.site-header {
  position   :relative;
  height     :4em;
  background :color(primary-color);
  z-index    :100;
  &--fixed {
    position   :fixed;
    top        :0;
    right      :0;
    left       :0;
    transition :all .4s;
    background :color-opa(primary-color-dark, .9);
    z-index    :100;
  }
}

.site-header-container {
  @include container();
}

.logo-nom {
  color       :#fff;
  font-family :$sans-serif;
  font-size :1em;
  line-height :4em;
  padding-left :.5em;

  @include susy-breakpoint($tablet-portrait) {
    padding-left :4em;
    font-size   :1.3em;
    line-height :3.3em;
  }
}

.nav-trigger {
  display    :block;
  position   :relative;
  top        :2em;
  right      :1em;
  width      :40px;
  height     :8px;
  float      :right;
  background :#fff;
  cursor     :pointer;

  @include susy-breakpoint($tablet-portrait) {
    display :none;
  }
  &:before,
  &:after {
    display    :block;
    position   :absolute;
    width      :40px;
    height     :8px;
    background :#fff;
    content    :'';
  }

  &:before {
    top :-15px;
  }
  &:after {
    bottom :-15px;
  }
}

.main-nav {
  @include susy-breakpoint($tablet-portrait) {
    height :4em;
    float  :right;
  }
}

.main-nav-ul {
  display         :none;
  position        :absolute;
  top             :4em;
  right           :0;
  width           :250px;
  margin          :0;
  padding         :0;
  background      :color(primary-color);
  line-height     :4em;
  text-align      :center;

  @include susy-breakpoint($tablet-portrait) {
    display    :block;
    position   :relative;
    top        :0;
    width      :100%;
    margin     :0 1em 0 0;
    background :none;
    text-align :inherit;
  }

  list-style-type :none;
  &--active {
    display :block;
  }
}

.main-nav li {
  display    :block;
  border-top :1px solid #ccc;

  @include susy-breakpoint($tablet-portrait) {
    display       :inline-block;
    padding-right :1em;
    border        :none;
  }
}

.main-nav a {
  transition      :opacity .3s ease;
  color           :color(primary-color-text);
  font-family     :$sans-serif;
  font-weight     :400;
  text-decoration :none;
  &:hover {
    color :color-opa(primary-color-text, .7);
  }
}

.home-icon i {
  color :color(accent-color);
}
