.liste li {
  padding-bottom  :1.5em;
  line-height     :22px;
  font-family     :$serif;
  list-style-type :none;
  position :relative;
  &:before {
    content: "\e905";
    font-family : icomoon;
    position :absolute;
    left : -30px;
  }

    &:nth-of-type(even) {
      &:before {color: color(accent-color);}
    }

  &:nth-of-type(odd) {
    &:before {
      color: #FF4637;
    }
  }
}

.clear-left {
  clear: both;
}

.plan-article {
  padding :gutter();
  
  margin-left :gutter();

  @include susy-breakpoint($tablet-portrait) {
  width :38%;
  float :right;
  margin-top: 4em;
  box-shadow: -8px 2px 8px -8px color-darker(divider-color, 5);
  margin-left: 2em;
  margin-bottom: 1em;
  }
}

.plan-article__ul {
  li {
    list-style-type :none;
    padding-bottom :2px;
    a {
      color: #618934;
      text-decoration :none;
      font-family :$sans-serif;
      transition: opacity .4s ease-out;
      &:hover {
        opacity :.8;
      }
    }
  }
}

.scroll {
  text-decoration :none;
  @include susy-breakpoint($tablet-portrait) {
  position :absolute;
  right :3em;
  bottom: 1em;

  }
  i {
    font-size :3em;
    color: color(accent-color);
  }
}