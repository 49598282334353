/* headers
  ============================= */
h1 {
  @include fz($h1-fz);
  @include lh($h1-lh);
  position :relative;
  background : color-lighter(primary-color, 9);
  border: 10px solid color-lighter(primary-color, 9);
  display :inline-block;
  padding: gutter() gutter(10);
  margin-bottom :.5em;
  letter-spacing: -1px;
  outline: 2px dashed color(primary-color-light);
  outline-offset: -15px;
  border-radius: 4px;
}

h2 {
  padding-top    : 1em;

  @include fz($h2-fz);
  @include lh($h2-lh);
}

h3 {
  @include fz($h3-fz);
  @include lh($h3-lh);
}

h4 {
  @include fz($h4-fz);
  @include lh($h4-lh);
}

h5,
h6 {
  @include fz($h5-fz);
}

/* paragraphes
  ============================= */
p {
  margin-bottom : 1em;
  font-family   : $serif;
  font-style    : normal;
  font-weight   : 400;

  @include lh($p-lh);

  &:last-of-type {
    margin-bottom : 0;
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family : $sans-serif;
  font-weight :400;
}
h1 {
  color :color(primary-text-color);
}

.text-link {
  text-decoration :none;
  color: color-darker(accent-color, 3);
  transition: color .3s;

  &:hover {
    color: color(accent-color);
  }
}