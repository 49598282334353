.question {
  font-style :italic;
  font-size :1.5em;
  background : color-opa(primary-color-light, .4);
  padding : 5px 10px;
  border: 1px solid color(divider-color);
  border-top-left-radius :10px;
  border-bottom-right-radius :10px;
  line-height :35px;
}

.icon-big-accent {
  font-size :1.5em;
  color: color(accent-color);
  padding-right :5px;
}

.icon-quotes-left {
  color: color(primary-color-dark);
}