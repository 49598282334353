$sans-serif : 'Josefin Sans', sans-serif;
$serif : 'Lora', serif;

/* font-size maps
  ============================= */
$h1-fz : (
        null    : 1em,
        320px   : 1.5em,
        375px   : 2em,
        768px   : 2.5em,
        1200px  : 3em
);

$h2-fz : (
        null    : 1.625em,
        320px   : 1.625em,
        375px   : 1.5em,
        768px   : 2em,
        1200px  : 2em
);

$h3-fz : (
        null    : 1.625em,
        320px   : 1.625em,
        375px   : 1.375em,
        768px   : 1.75em,
        1200px  : 1.75em
);

$h4-fz : (
        null    : 1.625em
);

$h5-fz : (
        null    : 1em
);

@mixin fz($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size : $fs-font-size;
    } @else {
      @media screen and(min-width : $fs-breakpoint) {
        font-size : $fs-font-size;
      }
    }
  }
}

/* line height maps
  ============================= */
$p-lh : (
        null    : 1.25em,
        320px   : 1.25em,
        375px   : 1.125em,
        768px   : 1.375em
);

$h1-lh : (
        null    : 1.25em,
        1200px  : 1.50em
);

$h2-lh : (
        null    : 1.15384615em,
        320px   : 1.15384615em,
        375px   : 1.25em
);

$h3-lh : (
        null    : 1.13636364em,
        320px   : 1.13636364em,
        375px   : 1.25em
);

$h4-lh : (
        null    : 1.11111111em,
        320px   : 1.11111111em,
        375px   : 1.22222222em
);

@mixin lh($lh-map) {
  @each $lh-breakpoint, $lh in $lh-map {
    @if $lh-breakpoint == null {
      line-height : $lh;
    } @else {
      @media screen and(min-width : $lh-breakpoint) {
        line-height : $lh;
      }
    }
  }
}
