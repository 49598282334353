.anim-mot {
    position: absolute;
    right: 4em;
    padding: 10px;
    border-radius: 4px;
    background: color-opa(accent-color, .8);
    color: color(primary-color-text);
    font-size: 1.4em;
    opacity: 0;

    &-1 {
        top: 0;
    }
    &-2 {
        top: 0;
    }
    &-3 {
        top: 0;
    }

    &-4 {
        top: 0;
        background: rgba(235, 111, 71, .9);
    }
}

#plante {
    position: absolute;
    right: 1em;
    bottom: 0;
    width: 350px;

    @include susy-breakpoint($tablet-portrait) {
        width: 300px;
    }
    @include susy-breakpoint($tablet-paysage) {
        width: 350px;
        height: 450px;
    }
}

#feuille_1 ,
#feuille_2 ,
#feuille_3 ,
#feuille_4 ,
#feuille_5 {
    background: #000;
    opacity: 0;
}

#puzzle ,
#questions-reponses ,
#logo ,
#compass {
    position: relative;
    width: 100px;
    height: 130px;
    margin: 1em auto 3em;
}

#cabinet {
    width: 200px;
    height: 130px;
    margin: 1em auto 3em;
}

#sun-cloud {
    width: 100%;
    height: 130px;
    margin: 1em auto 3em;
}

#maron {
    position: absolute;
    top: 0;
}

.st0 {
    fill: #8cc04d;
}

.st1 {
    fill: #3b5121;
}

.st2 {
    fill: #795649;
}

.orange-path {
    fill: #eb6f47;
}

.maron-path {
    fill: #855247;
}

.bleu-path {
    fill: #4e5eac;
}

.vert-path {
    fill: #6cc443;
}

.sun-color {
    fill: #ff6040;
}

.cloud-color {
    fill: #727272;
}

.fauteuil-d-color {
    fill: #8bc34a;
}

.fauteil-g-color {
    fill: #5d4037;
}

.table-color {
    fill: #795548;
}

.lampe-color {
    fill: #5e5e5e;
}

.interro {
    fill: #ff6040;
}

.suspension {
    fill: #00c937;
}

.question-border {
    fill: #727272;
}

.cls-1 ,
.cls-2 ,
.cls-3 ,
.cls-4 {
    fill: none; stroke-miterlimit: 10; stroke-width: 20px;
}

.cls-1 {
    stroke: #673e36;
}

.cls-1 ,
.cls-2 {
    stroke-linecap: round;
}

.cls-2 ,
.cls-4 {
    stroke: #7ac943;
}

.cls-3 {
    stroke: #ff6040;
}

// -----------------------------------
//  EMRD ANIM
// -----------------------------------
.emdr-anim-container {
    position: relative;
    height: 80px;
    margin-top: 2em;
    margin-bottom: 5em;
    overflow: hidden;
}

.emdr-anim {
    position: absolute;
    right: 0;
    left: 0;
    height: 40px;
    font-family: $sans-serif;
    font-size: 2em;
    font-style: italic;
    font-weight: 700;
    text-align: center;

    &--eye {
        top: -100px;
        color: color(accent-color);
        opacity: 0;
    }
    &--movement {
        top: -100px;
        color: color(primary-color-dark);
        opacity: 0;
    }
    &--desensitization {
        top: -100px;
        color: #ff4637;
        opacity: 0;
    }
    &--reprocessing {
        top: -100px;
        color: color(primary-color-dark);
        opacity: 0;
    }
}

#compass ,
#questions-emdr ,
#svg-idea,
#svg-chemin {
    display: block;
    position: relative;
    width: 100px;
    margin: 1em auto 1em;
}

.light {
    stroke-width: 2px;
}

#engrenages-emdr,
#fleches{
    display: block;
    width: 150px;
    margin: 2em auto;
}

#pointe_fleche_quatre {
    opacity : 0;
}

#haut_fleche_deux,
#bas_fleche_deux {
    stroke: color(accent-color);
}

#pointe_fleche_un {
    fill: color(accent-color);
}

#question-mark {
    display :block;
    margin: 2em auto;
    padding : 1em;
    width :150px;
}

.stagger-question {
    stroke-width: 8px;
    fill: none;
}
.question-mark-interro {
    stroke: color(accent-color);
}

.question-mark-point {
    stroke: color(accent-color);
}

.question-mark-circle {
    stroke: color(primary-color-light);
}

#gear-one {
    fill: #67c73f;
}

#gear-two {
    fill: #855247;
}

#bulb {
    fill: yellow;
}

#compass-aiguille {
    fill: color(accent-color);
}

#loupe-container {
    display: block;
    width: 150px;
    margin: 0 auto;
    padding: 0 2em;
}

#cross {
    fill: color(accent-color);
}

#loupe {
    fill: #000;
    stroke: #eee;
    stroke-width: 1px;
}

,
#anim-map,
#anim-clock,
#anim-tarif {
  width :40px;
  display :block;
  margin: 0 auto 2em;
}

.svg-color-marron {
  fill: color(primary-color);
}

.svg-accent-color {
  fill: #FF6040;
}

.svg-green {
  fill: color(accent-color);
}

.talign-center {
    text-align: center;
}

#svg-urgences {
    margin: 0 auto;
    display :block;
}

@keyframes pinpon {
    0% {
        fill: orange;
    }

    50% {
        fill:#0a8dff;
    }

    100% {
        fill: orange;
    }
}

#pinpon {
    animation: pinpon 1s infinite;
}
