$susy: (
        flow: ltr,
        math: fluid,
        output: float,
        gutter-position: after,
        container: 1440px,
        container-position: center,
        columns: 12,
        gutters: 1/4,
        column-width: false,
        global-box-sizing: content-box,
        last-flow: to,
        debug: (
                image: hide,
                color: rgba(#ff4b54, .4),
                output: background,
                toggle: top right,
        ),
        use-custom: (
                background-image: true,
                background-options: false,
                box-sizing: true,
                clearfix: false,
                rem: true,
        )
);

@include border-box-sizing;