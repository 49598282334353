.left-2-3 {
  @include susy-breakpoint($tablet-portrait) {
    @include span(8);
  }
}

.sidebar {
  @include susy-breakpoint($tablet-portrait) {
    @include span(4 last);
    border-left: 3px solid color(accent-color);
    margin-top :15em;
    h2 {
      margin-top :0;
      padding-top :0;
      text-align :center;
      text-transform :uppercase;
    }
  }
}

.bloc {
  border-radius: 7px;
  background :color(primary-color-text);
  box-shadow: 0 0 2px rgba(119, 123, 125, 0.89);
  padding : gutter();
  margin-top :gutter();
  margin-bottom :gutter();
}

.separator {
  margin :0;
  padding-top :1em;
  padding-bottom :1em;
  min-height :5em;

  background-image : url('./images/plant-separator.svg'),
  url('./images/plant-separator.svg'),
  url('./images/plant-separator.svg');
  background-repeat: no-repeat;
  background-position: 5% center, center center, 95% center;
  background-size: 120px;
}

.cf {
  @include break;
}

.nest-left-in-2-3 {
  @include susy-breakpoint($tablet-portrait) {
    @include span(6);

  }
}

.nest-right-in-2-3 {
  @include susy-breakpoint($tablet-portrait) {
    @include span(6 last);
  }
}