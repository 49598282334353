.site-footer {
  clear            :both;
  border-top       :10px solid color(accent-color);
  background-color :color(primary-color-dark);
  color            :color-darker(primary-color-text, 3);
  overflow         :auto;

  @include susy-breakpoint($tablet-portrait) {
    height :25em;
  }

  h4 {
    margin-bottom :10px;
    color         :color-darker(accent-color, 3);
    font-size     :1.4em;
  }

  p {
    &:first-of-type {
      margin-top :0;
    }
  }
}

.site-footer-container {
  @include container();
  @include pad(gutter());
}

.footer-sitemap,
.footer-resume,
.footer-infos,
.footer-adresse {
  margin-top    :1em;
  margin-bottom :1em;

  @include susy-breakpoint($tablet-portrait) {
    min-height :300px;
  }
}

.footer-sitemap {
  @include susy-breakpoint($tablet-portrait) {
    padding-left :gutter();
    border-left  :1px dashed color(divider-color);
  }
}

.footer-sitemap,
.footer-resume {
  @include susy-breakpoint($tablet-portrait) {
    border-right :1px solid color(divider-color);
    @include span(3);
  }
}

.footer-infos {
  @include susy-breakpoint($tablet-portrait) {
    border-right :1px solid color(divider-color);
    @include span(3);
  }
}

.footer-adresse {
  @include susy-breakpoint($tablet-portrait) {
    border-right :1px dashed color(divider-color);
    @include span(3 last);
  }
}

.footer-sitemap ul {
  margin-top :0;
  padding    :0;
}

.footer-sitemap ul li {
  padding-bottom  :.3em;
  list-style-type :none;
}

.footer-sitemap ul li a {
  transition      :color .3s;
  color           :color-darker(primary-color-text, 3);
  font-family     :$serif;
  text-decoration :none;

  &:hover {
    color :color(primary-color-text);
  }
}

.nicodevlink {
  color          :#e287b3;
  font-family    :$sans-serif;
  letter-spacing :1px;
  &:hover {
    opacity :.8;
  }
}

.site-footer-copyright {
  clear :both;

  p {
    display :inline-block;
  }
}

#heart {
  width        :1em;
  margin-right :4px;
  margin-left  :10px;
  fill         :#e287b3;
}

.legal a {
  color           :color(primary-color-light);
  text-decoration :none;
}

.pub {
  margin-right :gutter();
  float        :right;
}

.nicomail::after {
  content: 'contact.nicodev@gmail.com';
  font-family : $sans-serif;
  color: currentColor;
  padding-left : .5em;
}