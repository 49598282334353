html,
body {
  height :100%; // sticky footer
}

.hero {
  height     :35em;
  background :url('images/hero.jpg') no-repeat 40% 40% / cover;

  @include susy-breakpoint($macbook) {
    background :url('images/hero.jpg') no-repeat 40% 70% / cover;
  }
}

.content-wrapper {
  background      :url('images/main-bg.png');
  background-size :cover;
  position :relative;
  min-height     :calc(100vh - 22em); // sticky footer header + footer height

  @include pad(gutter());
  @include container();
}

.bloc-intro-home {
  min-height :585px;
  @include susy-breakpoint($tablet-portrait) {
    min-height : 580px;
  }
  @include susy-breakpoint($tablet-paysage) {
    min-height :540px;
  }
  @include susy-breakpoint($desktop) {
    min-height :560px;
  }
}

.curled-paper-container {
  position :relative;
  z-index :1;
  min-height :565px;
  
  @include susy-breakpoint($tablet-portrait) {
    min-height : 460px;
    @include span(7);
  }
  
  &:after {
    z-index :-1;
    position: absolute;
    content: "";
    bottom: 26px;
    right: 10px;
    left: auto;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    box-shadow: 0 15px 10px #777;
    transform: rotate(3deg);
  }
}

.home-svg-plant-anim {
  opacity : 0;
  border-radius   :7px;
  background      : rgba(255,255,255, .4);
  box-shadow      :0 0 2px rgba(119, 123, 125, .89);

  @include susy-breakpoint($tablet-portrait) {
    margin-top : 15px;
    min-height : 560px;
    @include span(5 last);
    position :relative;
    height :10em;
    opacity :1;
  }

  @include susy-breakpoint($tablet-paysage) {
    min-height :550px;
  }
}

.blocs-home-container {
  padding-bottom :2em;
  overflow       :auto;

  @include break;

  section {
    position :relative;
    display         :flex;
    min-height      :600px;
    margin-top      :gutter();
    border-radius   :7px;
    background      :rgba(255, 255, 255, 1);
    box-shadow      :0 0 2px rgba(119, 123, 125, .89);

    @include pad(gutter());
    @include susy-breakpoint($tablet-portrait) {
      @include gallery(6);
    }
    @include susy-breakpoint($tablet-paysage) {
      @include gallery(4);
    }

    flex-direction  :column;
    align-items     :stretch;
    justify-content :flex-start;

    h2 {
      text-align :center;
    }

    p {
      flex-grow :1;
    }
    a {
      position       :relative;
      padding-bottom :1.5em;
      color          :color(primary-text-color);
      font-family    :$sans-serif;
      font-size      :1.2em;

      align-self     :center;
      flex-shrink    :1;

      &:hover {
        color :color(accent-color);
        &:before {
          animation :leaf 2s ease-in infinite;
        }
      }

      &:before {
        display    :block;
        position   :absolute;
        top        :-1px;
        left       :-40px;
        width      :30px;
        height     :30px;
        background :url('./images/green-leaf.svg') no-repeat center center;
        content    :'';
      }
    }
  }
}

@keyframes leaf {
  0% {
    transform :translate(0, 0) rotate(0deg);
  }
  50% {
    transform :translate(-5px, -5px) rotate(-20deg);
  }
  75% {
    transform :translate(-10px, -10px) rotate(30deg);
  }
  100% {
    transform :translate(0, 0) rotate(0deg);
  }
}

.subtitle {
  font-size :1.2em;
  text-align :center;
  width : 80%;
  font-family :$sans-serif;
  margin: 1.5em auto;
  border-top: 2px solid color(accent-color);
  border-bottom : 2px solid color(accent-color);
  padding-top :gutter();
  padding-bottom :gutter();
  color: color(secondary-text-color);
}

.color-tooltip {
  padding-left :4px;
  padding-right :4px;
  padding-bottom :1px;
  background : color-opa(accent-color, .3);
  cursor : help;
}

.logos-roll {
  width :100%;
  background : color-opa(divider-color, .1);
  overflow :hidden;
  height :150px;
  margin: 4em auto 1em;
  position :relative;
}

.logos-roll__img {
  position :absolute;
  right :0;
  left :0;
  top :1em;
}


.img-center {
  display :block;
  margin: 1em auto;
}

.logo-emdr-france {
  width :100px;
}

.logo-loiread {
  width :100px;
}

.logo-ffpp {
  width :200px;
}

#logo-roll-1 {
  left: 300px;
  top: 40px;
}

#logo-roll-2 {
  top: 10px;
  left :100px;
  z-index : 100;
}

#logo-roll-3 {
  top: 40px;
}

#logo-roll-4 {
  top: 40px;
}

#logo-roll-3,
#logo-roll-4 {
  left :-200px;
  opacity :0;
}

.identity {
  width :250px;
  display :block;
  margin : 0 auto 2em;
}

.subtitle-gray {
  color: color(secondary-text-color);
  margin-top :0;
  padding-top :0;
  padding-left : gutter();
}

.signature {
  font-family: 'Tangerine', cursive;
  font-size :2em;
  position :absolute;
  right :1.5em;
  bottom :.5em;
  color: color(primary-text-color);

  @include susy-breakpoint($tablet-portrait) {
    bottom :1em;
  }
}

.tel-cab {
  display :inline-block;
  padding : .3em .6em;
  font-family : $sans-serif;
  font-size :2em;
  color: #fff;
  border-radius: 4px;
  float :right;
}

.avec-plan-droite {
  @include susy-breakpoint($tablet-portrait) {
      @include gallery(6);
    }
}

.break {
  @include susy-breakpoint($tablet-portrait) {
      @include full();
    }
}

.photo-cabinet {
  max-width: 100%;
  height: auto;
  margin-top: 1em;
}