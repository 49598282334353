@font-face {
  font-family: 'icomoon';
  src:    url('fonts/icomoon.eot?4y75ya');
  src:    url('fonts/icomoon.eot?4y75ya#iefix') format('embedded-opentype'),
  url('fonts/icomoon.woff2?4y75ya') format('woff2'),
  url('fonts/icomoon.ttf?4y75ya') format('truetype'),
  url('fonts/icomoon.woff?4y75ya') format('woff'),
  url('fonts/icomoon.svg?4y75ya#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-noun_89591:before {
  content: "\e906";
}
.icon-arrow-circle-right:before {
  content: "\e905";
}
.icon-forward2:before {
  content: "\e904";
}
.icon-forward:before {
  content: "\e901";
}
.icon-quotes-left:before {
  content: "\e902";
}
.icon-bubbles:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e900";
}

