th {
  font-family :$sans-serif;
}

td {
  font-family :$serif;
}

.table-horaires,
.table-tarifs {
  @include susy-breakpoint($tablet-portrait) {
    margin: 0 auto;
  }
}

.table-horaires tr,
.table-tarifs tr {
  height :40px;
  border :1px solid black;
}

.table-horaires th,
.table-tarifs th {
  border-right :1px solid #fff;
  background   :color(primary-color-light);
  color        :#000;
  &:last-of-type {
    border-right :none;
  }
}

.table-horaires td,
.table-tarifs td {
  width        :140px;
  border-right :1px solid #fff;

  &:last-of-type {
    border-right :none;
  }
}

.cabinet-ferme {
  background :repeating-linear-gradient(45deg,
          #eee 1px, #eee 1px,
          #fff 10px, #fff 10px);
}

.cabinet-ouvert {
  background  :color-lighter(accent-color, 3);
  font-weight :700;
  text-align  :center;
}

.table-tarifs td {
  background  :color-lighter(accent-color, 3);
  font-weight :700;
  text-align  :center;
}

tfoot td {
  background   :color(primary-color-light) !important;
  font-size: .8em;
  font-family: $sans-serif;
}
 